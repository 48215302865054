import React from 'react'

import Layout from '../components/Layout'
import About from '../components/About'

export default ({ location, pageContext }) => (
  <Layout
    pageTitle={pageContext.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <About locale={pageContext.locale} />
  </Layout>
)
