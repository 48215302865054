import React, { Component } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

/* Images */
import background_1 from '../../img/about_background_1.jpg'
import background_2 from '../../img/about_background_2.jpg'

import slide_1 from '../../img/about_certyfikaty.jpg'
import slide_2 from '../../img/about_slider.jpg'
import slide_3 from '../../img/about_kalendarz.jpg'

import detale from '../../img/about_detale.jpg'
import mozliwosci from '../../img/about_mozliwosci.jpg'
import standardy from '../../img/about_standardy.jpg'
import mapa from '../../img/about_mapa.jpg'

/* Texts */
import texts from '../../constants/texts'
import paths from '../../constants/paths'

export default class About extends Component {
  state = { url: '' }
  componentDidMount () {
    this.goToId()
  }
  componentDidUpdate () {
    if (this.state.url && this.state.url != window.location.href) {
      this.goToId()
    }
  }
  goToId = () => {
    this.setState({ url: window.location.href })
    const arr = window.location.href.split('#')
    const id = arr[arr.length - 1]
    const el = document.getElementById(id)
    if (el) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView()
      }, 100)
    }
  }

  getAboutLink = id => Object.keys(texts[this.props.locale].links.about)[id]

  render () {
    const { locale } = this.props
    return (
      <div className='main-container'>
        <section
          className='cover cover-features imagebg height-70 padding'
          data-overlay='5'
        >
          <div
            className='background-image-holder bottom'
            style={{
              background: `url(${background_1})`,
              opacity: 1
            }}
          >
            <img alt='background' src={background_1} />
          </div>
          <div className='container pos-vertical-center'>
            <div className='row'>
              <div className='col-sm-9 col-md-8'>
                <h1>
                  <b>{texts[locale].about.section_1.title}</b>
                </h1>
                <p className='lead'>
                  {texts[locale].about.section_1.paragraph}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id={this.getAboutLink(1)}
          className='switchable imagebg padding'
          data-overlay='8'
        >
          <div
            className='background-image-holder'
            style={{ background: `url(${mapa})`, opacity: 1 }}
          >
            <img alt='background' src={mapa} />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-md-6'>
                <ul className='accordion accordion-1 accordion--oneopen'>
                  <li className='active'>
                    <div className='accordion__title'>
                      <span className='h5'>
                        <p>{texts[locale].about.section_2.title}</p>
                      </span>
                    </div>
                    <div className='accordion__content'>
                      <p className='lead'>
                        {texts[locale].about.section_2.paragraph_1}
                        <br />
                        <br />
                        {texts[locale].about.section_2.paragraph_2}
                        <br />
                        <br />
                        {texts[locale].about.section_2.paragraph_3}
                        <br />
                      </p>
                    </div>
                  </li>
                </ul>
                <br />
              </div>
              <div className='col-md-6 col-sm-6'>
                <div
                  className='slider box-shadow-wide border--round'
                  data-arrows='true'
                  data-paging='true'
                >
                  <ul className='slides'>
                    <li>
                      <img alt='img1' src={slide_1} />
                    </li>
                    <li>
                      <img alt='img2' src={slide_2} />
                    </li>
                    <li>
                      <img alt='img3' src={slide_3} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='imagebg space--sm padding' data-overlay='6'>
          <div
            className='background-image-holder'
            style={{ background: `url(${background_2})`, opacity: 1 }}
          >
            <img alt='background' src={background_2} />
          </div>
          <div className='container'>
            <div className='row'>
              <div className='cta cta--horizontal text-center-xs'>
                <div className='col-sm-4'>
                  <h4>
                    <b>{texts[locale].about.section_3.title}</b>
                  </h4>
                </div>
                <div className='col-sm-4'>
                  <p className='lead'>
                    {texts[locale].about.section_3.paragraph}
                  </p>
                </div>
                <div className='text-right text-center-xs col-sm-2'>
                  <AniLink
                    duration={1}
                    cover
                    direction='up'
                    bg='#230d54'
                    to={`${paths[locale]['/about/'].path}#${
                      texts[locale].links.contact
                    }`}
                    className='btn btn--primary type--uppercase'
                  >
                    <span className='btn__text'>
                      {texts[locale].links.contact_us}
                    </span>
                  </AniLink>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id={this.getAboutLink(2)}
          className='switchable feature-large bg--dark padding'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <img
                  alt='Image'
                  className='border--round box-shadow-wide'
                  src={detale}
                />
              </div>
              <div className='col-sm-6 col-md-5'>
                <div className='switchable__text'>
                  <h2>
                    <b>{texts[locale].about.section_4.title}</b>
                  </h2>
                  <p className='lead'>
                    {texts[locale].about.section_4.paragraph}
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id={this.getAboutLink(3)}
          className='switchable padding feature-large switchable--switch bg--primary space--xs'
        >
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <img
                  alt='Image'
                  className='border--round box-shadow-wide'
                  src={mozliwosci}
                />
              </div>
              <div className='col-sm-6 col-md-5'>
                <div className='switchable__text'>
                  <h2>
                    <b>{texts[locale].about.section_5.title}</b>
                  </h2>
                  <p className='lead'>
                    {texts[locale].about.section_5.paragraph} <br />
                    <br />
                    <b>{texts[locale].about.section_5.examples.title}</b>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='bg--primary space--xxs padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <ul className='process-stepper clearfix' data-process-steps='4'>
                  <li>
                    <span>
                      <b>{texts[locale].about.section_5.examples.name_1}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>{texts[locale].about.section_5.examples.name_2}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>{texts[locale].about.section_5.examples.name_3}</b>
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>{texts[locale].about.section_5.examples.name_4}</b>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          id={this.getAboutLink(4)}
          className='cover imagebg text-center height-70 padding'
          data-overlay='8'
        >
          <div
            className='background-image-holder'
            style={{ background: `url(${standardy})`, opacity: 1 }}
          >
            <img alt='background' src={standardy} />
          </div>
          <div className='container pos-vertical-center'>
            <div className='row'>
              <div className='col-sm-9 col-md-8'>
                <h1>
                  <b>{texts[locale].about.section_6.title}</b>
                </h1>
                <p className='lead'>
                  <span>{texts[locale].about.section_6.paragraph_1}</span>
                  <br />
                  {texts[locale].about.section_6.paragraph_2}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id={this.getAboutLink(5)}
          className='unpad cta cta-2 text-center bg--dark padding'
        >
          <div className='container' id={texts[locale].links.contact}>
            <div className='row'>
              <div className='col-sm-12'>
                <h2 className='type--uppercase'>
                  <b>{texts[locale].about.team.title}</b>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className='text-center bg--dark padding'>
          <div className='container'>
            <div className='row'>
              <div className='feature feature-9'>
                <h4 className='type--uppercase'>
                  <b>{texts[locale].about.team.section_1.title}</b>
                </h4>
                {texts[locale].about.team.section_1.names.map((name, i) => (
                  <div className='col-sm-6' key={i}>
                    <h4>
                      <br />
                      <b>{name.name_1}</b>
                      <br />
                      <b>{name.name_2}</b>
                    </h4>
                    <div>
                      {name.bold_line_1 ? (
                        <React.Fragment>
                          <br />
                          <h4>
                            <b>{name.bold_line_1}</b>
                          </h4>
                          {name.line_4} <span className="adres">{name.line_5}</span>
                          <br />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {name.line_4} <span className="adres">{name.line_5}</span>
                          <br />
                          {name.line_2}
                          <br />
                        </React.Fragment>
                      )}
                      {name.line_3}
                      <br />
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='container' style={{ marginTop: '50px' }}>
            <div className='row'>
              <div className='feature feature-9'>
                <h4 className='type--uppercase'>
                  <b>{texts[locale].about.team.section_2.title}</b>
                </h4>
                {texts[locale].about.team.section_2.names.map((name, i) => (
                  <div className='col-sm-6' key={i}>
                    <h4>
                      <br />
                      <b>{name.name}</b>
                    </h4>
                    <div>
                      {name.line_1 ? (<React.Fragment>{name.line_1}<br /></React.Fragment>) : (<React.Fragment></React.Fragment>)}
                       {name.line_2 ? (<React.Fragment>{name.line_2}<br /></React.Fragment>) : (<React.Fragment></React.Fragment>)}
                        {name.line_3 ? (<React.Fragment>{name.line_3}<br /></React.Fragment>) : (<React.Fragment></React.Fragment>)}
                         {name.line_4 ? (<React.Fragment>{name.line_4}<br /></React.Fragment>) : (<React.Fragment></React.Fragment>)}
                      {name.line_6} <span className="adres">{name.line_7}</span>
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className='imageblock padding switchable feature-large bg--dark space--md'>
          <div className='imageblock__content col-md-6 col-sm-5 col-xs-12 pos-right'>
            <div className='map-container'>
              <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2528.4338466828317!2d18.702465415463315!3d50.67477147905736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710dcccded8b0dd%3A0xfd92f60e626b3fbd!2sBENSKI+sp.+z+o.o.+sp.k.!5e0!3m2!1sen!2spl!4v1544788851603' />
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-5 col-sm-6'>
                <h3>
                  {texts[locale].home.section_6.title.map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </h3>

                {texts[locale].home.section_6.paragraphs.map((paragraph, i) => (
                  <p className='lead' key={i}>
                    {Array.isArray(paragraph)
                      ? paragraph.map((line, k) => (
                        <React.Fragment key={k}>
                          <span className="adres">{line}</span>
                          <br />
                        </React.Fragment>
                      ))
                      : paragraph}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
